@import 'ngx-toastr/toastr';

.toast-container {
  pointer-events: unset;
}

.toast-container .toast-error {
  background-image: none;
}

/*
  Adjusting the ngx-toastr position classes a bit to make them work with the snackbar,
  they should not break the stock toast.
 */
.toast-container > bgo-toast {
  background-color: unset;
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
